.page-introduction {
	position: relative;
	overflow: hidden;
	@extend .jumbotron !optional;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	padding-left: 0;
	padding-right: 0;
	border-radius: 0;
	.page-title {
		@extend .display-4 !optional;
		background-color:map-get($theme-colors, primary);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		display: inline-block;
		i {
			&.morning-moon {
				-webkit-text-fill-color: lightblue;
			}
			&.morning-sun {
				-webkit-text-fill-color: #fad859;
			}
			&.afternoon-sun {
				-webkit-text-fill-color: #e87e04;
			}
			&.evening-sun {
				-webkit-text-fill-color: #d35400;
			}
			&.night-moon {
				-webkit-text-fill-color: lightblue;
			}
			
		}
	}
}