.navbar {
    .navbar-brand {
        img {
            @extend .py-2;
        }
    }
}

.nav {
    width: 100%;
    .bordered {
        border-top: 1px solid rgba(map-get($theme-colors, charcoal-80), 1);
        &.active {
            background-color: map-get($theme-colors, charcoal-80);
            box-shadow: -2px 0px 5px  map-get($theme-colors, charcoal-80);
        }
    }
    .nav-header {
        @extend .nav-link;
        padding: 1rem;
        line-height: 1;
        &[aria-expanded="true"]{
            color: map-get($theme-colors, secondary);
        }
        .fa-caret-right {
            transform: rotate(90deg);
            transition: .25s;
        }
        &.collapsed {
            > .fa-caret-right {
                transform: rotate(0deg);
            }
        }
    }
}

#sidebar {
    line-height:1.1;
    background: map-get($theme-colors, primary);

    overflow-x: scroll;
   

    /* The emerging W3C standard
   that is currently Firefox-only */
    scrollbar-base-color: transparent;
    scrollbar-width: thin;
    scrollbar-color: transparent rgba(#222, 0.3);
  
  
  /* Works on Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
      height:100px;
    background-color: rgba(#222, 0.3);
  }
  &::-webkit-scrollbar-corner {
      background: transparent;
  }

    .nav-header {
        padding: 0.6rem 1rem;
    }
	.nav-link {
        padding: 0.6rem 1rem;
        color: #fff;
        &.active {
            font-weight:700;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
        }
    }
}

.fixed-top {
    height: 100vh;
    transition: 0.25s;
    @include media-breakpoint-down(md) {
        transform: translateX(-100%);
    }
}
.nav-footer {
    .social-media {
        padding: 0 7.5px;
    }
}

.mobile-nav-trigger {
    transition-duration:.5s;
    display: block;
    position: absolute;
    clip: rect(0,0,0, 0);
}
.mobile-nav-icon {
    display: none;
}
@include media-breakpoint-down(md) {
    .fixed-top {
        overflow-y: scroll;
    }
    .mobile-nav-icon {
        // transition-duration:.5s;
        // transform: rotate(0deg);
        display: inline-block;
        z-index: 10001;
        width: 25px;
        height: 25px;
        margin: 0;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 23px;
        font-size: 12px;
        color: white;
        span {
            transition-duration:.5s;
            transform: rotate(0deg);
            display: block;
            position: absolute;
            background: linear-gradient(to left, #0575E6, #021B79);;
            height: 2px;
            width: 100%;
            opacity: 1;
            left: 0;
            &:nth-child(1) {
                top: 2px;
            }
            &:nth-child(2),
            &:nth-child(3) {
                top: 7px;
                z-index: -1;
            }
            &:nth-child(4) {
                top: 12px;
            }
        }
    }
    .mobile-nav-trigger:checked ~ .mobile-nav-icon {
    	position: fixed;
        span {
            background:#fff;
            &:nth-child(1) {
                top: 10px;
                width: 0%;
                left: 50%;
                background: transparent;
            }
            &:nth-child(2) {
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
            }
            &:nth-child(4) {
                top: 10px;
                width: 0%;
                left: 50%;
                background-color: transparent;
            }
        }
    }
    .mobile-nav-trigger:checked ~ .fixed-top {
        transform: translateX(0);
    }
}
