.bg {
  background-size:cover;
  background-position: center;
  height: 200px;
  &.dlzfold {
    background-image: url(/assets/img/print/setfords-z-fold-example.webp);
  }
  &.a5dlf {
    background-image: url(/assets/img/print/a5/double-sided/landscape/a5-double-sided-landscape-front.jpg);
  }
  &.a5dlb {
    background-image: url(/assets/img/print/a5/double-sided/landscape/a5-double-sided-landscape-front.jpg);
  }
  &.a5dpf {
    background-image: url(/assets/img/print/a5/double-sided/portrait/a5-double-sided-portrait-front.jpg);
  }
  &.a5dpb {
    background-image: url(/assets/img/print/a5/double-sided/portrait/a5-double-sided-portrait-back.jpg);
  }
  &.a5sp {
    background-image: url(/assets/img/print/a5/single-sided/a5-single-sided-portrait.webp);
  }
  &.pull-up-banner {
    background-image: url(/assets/img/print/pull-up-banner-image.webp);
  }
  &.businesscards {
    background-image: url(/assets/img/print/business-card-mockup.webp);
  }
  &.eshot {
    background-image: url(/assets/img/print/eshot-mockup.webp);
  }
  &.other {
    background-image: url(/assets/img/print/other-mockup.jpg);
  }
  &.vidcalls {
    background-image: url(/assets/img/setfords-video-call-backgrounds.jpg);
  }
}

.bg-primary-transparent {
  background-color: rgba(map-get($theme-colors, primary), 0.5);
}
