/*
Docs at http://http://simpleweatherjs.com
Look inspired by http://www.degreees.com/
Used for demo purposes.
Weather icon font from http://fonts.artill.de/collection/artill-weather-icons
DO NOT hotlink the assets/font included in this demo. If you wish to use the same font icon then download it to your local assets at the link above. If you use the links below odds are at some point they will be removed and your version will break.
*/
@font-face {
	font-family: 'weather';
	src: url('/assets/fonts/artill_clean_icons-webfont.eot');
	src: url('/assets/fonts/artill_clean_icons-webfont.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/artill_clean_icons-webfont.woff') format('woff'),
	url('/assets/fonts/artill_clean_icons-webfont.ttf') format('truetype'),
	url('/assets/fonts/artill_clean_icons-webfont.svg#artill_clean_weather_iconsRg') format('svg');
	font-weight: normal;
	font-style: normal;
}
#weather {
	display: none;
	#weatherfeed {
		.weather-title {
			@extend .display-3;
			text-align: center;
			padding:0;
			margin:0;
		}
		.weather-icon {
			font-family: weather;
			font-weight: normal;
			font-style: normal;
			font-size: 135px;
			line-height: 0;
			position: absolute;
			right: 180px;
			top: 60px;
		}
		p {
			padding:0;
			margin:0;
		}
	}
}

.icon-0:before { content: ":"; }
.icon-1:before { content: "p"; }
.icon-2:before { content: "S"; }
.icon-3:before { content: "Q"; }
.icon-4:before { content: "S"; }
.icon-5:before { content: "W"; }
.icon-6:before { content: "W"; }
.icon-7:before { content: "W"; }
.icon-8:before { content: "W"; }
.icon-9:before { content: "I"; }
.icon-10:before { content: "W"; }
.icon-11:before { content: "I"; }
.icon-12:before { content: "I"; }
.icon-13:before { content: "I"; }
.icon-14:before { content: "I"; }
.icon-15:before { content: "W"; }
.icon-16:before { content: "I"; }
.icon-17:before { content: "W"; }
.icon-18:before { content: "U"; }
.icon-19:before { content: "Z"; }
.icon-20:before { content: "Z"; }
.icon-21:before { content: "Z"; }
.icon-22:before { content: "Z"; }
.icon-23:before { content: "Z"; }
.icon-24:before { content: "E"; }
.icon-25:before { content: "E"; }
.icon-26:before { content: "3"; }
.icon-27:before { content: "a"; }
.icon-28:before { content: "A"; }
.icon-29:before { content: "a"; }
.icon-30:before { content: "A"; }
.icon-31:before { content: "6"; }
.icon-32 {
	color: orange;
	&:before { 
		content: "1";
	}
}
.icon-33:before { content: "6"; }
.icon-34:before { content: "1"; }
.icon-35:before { content: "W"; }
.icon-36:before { content: "1"; }
.icon-37:before { content: "S"; }
.icon-38:before { content: "S"; }
.icon-39:before { content: "S"; }
.icon-40:before { content: "M"; }
.icon-41:before { content: "W"; }
.icon-42:before { content: "I"; }
.icon-43:before { content: "W"; }
.icon-44:before { content: "a"; }
.icon-45:before { content: "S"; }
.icon-46:before { content: "U"; }
.icon-47:before { content: "S"; }