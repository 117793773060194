// Bootstrap overrides

// Table of Contents
//
// Options
// Components
// Colors
// Fonts
// Links
// Grid breakpoints
// Cards

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: false;

//
// Color system
//

$white:  #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
);

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #FA6A6A;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #6AFAA6;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
);

$theme-colors: (
  primary: #282837,
  secondary: #a8ffb0,
  tertiary: #021B79,
  success: $green,
  info: #6B6AFA,
  warning: $yellow,
  danger: $red,
  light: $gray-200,
  dark: $gray-800,
  charcoal-80: #53535F
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:              .875rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$font-family-sans-serif: 'Inter', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-weight-normal: 300;
$font-weight-bold: 500;
$headings-font-weight: 300;
$headings-font-family: "BeausiteSlickTrial-Light";

// Links
//
// Style anchor elements.
$link-color:            theme-color("primary");
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1340px
);
@include _assert-ascending($container-max-widths, "$container-max-widths");

// Cards

$card-border-color: #dddddd;

// Pagination

$pagination-active-bg:                 theme-color("secondary");
$pagination-active-border-color:       theme-color("secondary");

// Body
//
// Settings for the `<body>` element.

$body-bg:       $white;
$body-color:    $black;

// Links
//
// Style anchor elements.


// Components
//
// Define common padding and border radius sizes and more.
$border-width:                1px;
$border-radius:               .25rem ;
$border-radius-lg:            .3rem ;
$border-radius-sm:            .2rem ;