.btn-rounded {
    border-radius: .7rem;
    &:hover {
        background-color: map-get($theme-colors, secondary);
    }
}

.btn-wrap-center {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}
