/**
* CHRISTMAS
*/

#christmas_cntdwn {
	position: relative;
}

.dash {
	display: inline-block;
}
.dash .digit {
	display: inline-block;
	margin-right: -4px;
}
.dash_title {
	display: inline-block;
	margin-left: 4px;
}

.let_it_snow {
	position: absolute;
	animation-timing-function: ease-out;
	z-index: 1000;
	pointer-events: none;
	background: transparent url(/assets/img/snow.svg) center center no-repeat;
	#pid_571 & {
		background: transparent url(/assets/img/kdickinson.png) center center no-repeat;
		background-size: 100px;
		width: 100px;
		height: 100px;
		animation-name: snow_anim_special;
	}
	&.snow_type_1 {
		width: 25px;
		height: 25px;
	}
	&.snow_type_2 {
		width: 15px;
		height: 15px;
	}
	&.snow_type_3 {
		width: 10px;
		height: 10px;
	}
	&.snow_duration_1 {
		animation-duration: 16s;
	}
	&.snow_duration_2 {
		animation-duration: 14s;
	}
	&.snow_duration_3 {
		animation-duration: 12s;
	}
	&.snow_duration_4 {
		animation-duration: 10s;
	}
	&.snow_duration_5 {
		animation-duration: 8s;
	}
	&.snow_delay_1 {
		animation-delay: 5s;
	}
	&.snow_delay_2 {
		animation-delay: 4s;
	}
	&.snow_delay_3 {
		animation-delay: 3s;
	}
	&.snow_delay_4 {
		animation-delay: 2s;
	}
	&.snow_delay_5 {
		animation-delay: 1s;
	}
	&.snow_anim_1 {
		animation-name: snow_anim_1;
	}
	&.snow_anim_2 {
		animation-name: snow_anim_2;
	}
	&.snow_anim_3 {
		animation-name: snow_anim_3;
	}
	&.snow_anim_4 {
		animation-name: snow_anim_4;
	}
	&.snow_anim_5 {
		animation-name: snow_anim_5;
	}
}
@keyframes snow_anim_fade {
	0%   { opacity: 1; }
	60%  { opacity: 1; }
	100% { opacity: 0; }
}
@keyframes snow_anim_1 {
	0%   { transform: translate(0, 0) rotate(0); }
	100% { transform: translate(-70px, 730px) rotate(70deg); }
}
@keyframes snow_anim_2 {
	0%   { transform: translate(0, 0) rotate(0); }
	100% { transform: translate(70px, 740px) rotate(-70deg); }
}
@keyframes snow_anim_3 {
	0%   { transform: translate(0, 0) rotate(0); }
	100% { transform: translate(30px, 650px) rotate(45deg); }
}
@keyframes snow_anim_4 {
	0%   { transform: translate(0, 0) rotate(0); }
	100% { transform: translate(-30px, 700px) rotate(-45deg); }
}
@keyframes snow_anim_5 {
	0%   { transform: translate(0, 0) rotate(0); }
	100% { transform: translate(0px, 760px) rotate(50deg); }
}

@keyframes snow_anim_special {
	0%   { transform: translate(0, 0) rotate(0); }
	100% { transform: translate(0px, 760px) rotate(-360deg); }
}
