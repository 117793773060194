.loading-change {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 20% 0 0 0;
	text-align: center;
	vertical-align: middle;
	background: rgba(255,255,255,0.9);
	z-index:100;
}