@import "components/navbar";
@import "components/page-introduction";
@import "components/display";
@import "components/backgrounds";
@import "components/section";
@import "components/card";
@import "components/footer";
@import "components/btn";
@import "components/carousel";
@import "components/dashboard-latest-joiner";
@import "components/label";
@import "components/invoices";
@import "components/toasts";
@import "components/animations";

html {
	position: relative;
	min-height: 100%;
}

body {
	overflow-y: scroll;
	margin-bottom: 3.5rem;
	> .container {
		padding-bottom: 3.5rem;
	}
}
