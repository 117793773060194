@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: "BeausiteSlickTrial-Light";
    src: url("/assets/fonts/BeausiteSlickWebTrial-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
}

@import "lib/bootstrap/bootstrap";
@import "lib/dropzone/dropzone";
@import "lib/weather/weather";
@import "lib/christmas/christmas";
@import "lib/timeline/timeline";
@import "lib/typeahead/typeahead";
@import "theme/theme";